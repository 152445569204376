import "./footer.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FacebookIcon from "@mui/icons-material/Facebook";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import TelegramIcon from "@mui/icons-material/Telegram";

function Footer() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [popup, setPopup] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("setting") !== null && localStorage.getItem("setting") !== "undefined") {
            setSetting(JSON.parse(localStorage.getItem("setting")));
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setSetting(res.data.data[0]);
            });
        }
    }, []);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audio, setAudio] = useState(null);
    useEffect(() => {
        if (localStorage.getItem("mp3")?.length > 5) {
            setAudio(new Audio(localStorage.getItem("mp3")));
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setAudio(new Audio(res.data.data[0]?.mp3));
                localStorage.setItem("mp3", res.data.data[0]?.mp3);
            });
        }
        return () => {
            if (audio) {
                audio.pause();
            }
        };
    }, []);
    const toggleAudio = () => {
        if (audio) {
            if (audio.paused) {
                audio.play();
            } else {
                audio.muted = !audio.muted;
            }
            setIsPlaying(!isPlaying);
        }
    };
    return (
        <>
            <footer>
                <div class="footer_top">
                    <div className="container">
                        <div class="footer-cont">
                            <section class="lc">
                                <div class="lc-cont">
                                    <div class="lc-tit">
                                        <img src={require("../../statics/footer/blue-tick.svg").default} />
                                    </div>
                                    <img src={require("../../statics/footer/license-curacao.png")} class="lc-c" />
                                    <p>Le MaCau </p>
                                    <div class="footer-copy">Copyright ©Le MaCau Reserved</div>
                                </div>
                                <div class="lc-cont">
                                    <div class="lc-tit">Certification</div>
                                    <div class="lc-group">
                                        <img src={require("../../statics/footer/ftr-cert-bmm.png")} class="lc-g icon-s" />
                                        <img src={require("../../statics/footer/ftr-cert-itech.png")} class="lc-g icon-s" />
                                        <img src={require("../../statics/footer/ftr-cert-gli.png")} class="lc-g icon-s" />
                                        <img src={require("../../statics/footer/ftr-cert-tst.png")} class="lc-g" />
                                        <img src={require("../../statics/footer/ftr-cert-godaddy.png")} class="lc-g" />
                                    </div>
                                </div>
                                <div class="lc-cont">
                                    <div class="lc-tit">Security</div>
                                    <div class="lc-group">
                                        <img src={require("../../statics/footer/ftr-security-iovation.png")} class="lc-g" />
                                        <img src={require("../../statics/footer/ftr-security-threatmetrix.png")} class="lc-g" />
                                    </div>
                                    <div class="lc-tit">Responsible Gaming</div>
                                    <div class="lc-group">
                                        <img src={require("../../statics/footer/ftr-rpsb-18.png")} class="lc-g icon-s" />
                                        <img src={require("../../statics/footer/ftr-rpsb-gamcare.png")} class="lc-g icon-s" />
                                        <img src={require("../../statics/footer/ftr-rpsb-aware.png")} class="lc-g icon-s" />
                                    </div>
                                </div>
                            </section>
                            <section class="lc lc-btm">
                                <div class="lc-cont">
                                    <div class="lc-group">
                                        <div class="footer-links">
                                            <a data-code="aboutUs" href="/info/aboutUs">
                                                Về Le MaCau
                                            </a>
                                            <a data-code="userAgreement" href="/info/userAgreement">
                                                Điều Khoản Và Điều Kiện
                                            </a>
                                            <a data-code="userPrivacy" href="/info/userPrivacy">
                                                Quyền riêng tư
                                            </a>
                                            <a data-code="responsibleGambling" href="/info/responsibleGambling">
                                                Chơi có trách nhiệm
                                            </a>
                                            <a data-code="disclaimer" href="/info/disclaimer">
                                                Miễn trách nhiệm
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="lc-cont">
                                    <div class="lc-group sns">
                                        <img src={require("../../statics/footer/media_icon_fb.svg").default} class="lc-g icon-s" />
                                        <img src={require("../../statics/footer/media_icon_youtube.svg").default} class="lc-g icon-s" />
                                        <img src={require("../../statics/footer/media_icon_ig.svg").default} class="lc-g icon-s" />
                                        <img src={require("../../statics/footer/media_icon_tiktok.svg").default} class="lc-g icon-s" />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="container">
                        <img src={require("../../statics/footer/full_product.png")} />
                    </div>
                </div>
            </footer>
            <div className="side-menu">
                <ul>
                    <li id="shortcut_onlinecs">
                        <a href={setting?.cskh} target="_blank" rel="nofollow">
                            <SupportAgentIcon />
                            <h2>CSKH trực tuyến</h2>
                            <p data-cont="Phục vụ 24/7">Phục vụ 24/7</p>
                        </a>
                    </li>
                    <li id="shortcut_custom1">
                        <a href={setting?.cskh2} target="_blank" rel="nofollow">
                            <TelegramIcon />
                            <h2>Telegram</h2>
                            <p data-cont="Phục vụ 24/7">Phục vụ 24/7</p>
                        </a>
                    </li>
                    {/*<li id="shortcut_im2">
                        <a href="https://www.facebook.com/vietnam AE888/" target="_blank" rel="nofollow">
                            <FacebookIcon />
                            <h2>Kênh Facebook</h2>
                            <p data-cont="Kênh chính thức">Kênh chính thức</p>
                        </a>
                    </li>
                    <li id="shortcut_im1">
                        <a href="https://m.facebook.com/msg/187383207789632/" target="_blank" rel="nofollow">
                            <img src={require("../../statics/footer/messenger.png")} className="shortcut_img" />
                            <h2>Message Facebook</h2>
                            <p data-cont="Hỗ trợ 24/24">Hỗ trợ 24/24</p>
                        </a>
                    </li>
                    <li id="shortcut_qa">
                        <a href="https://AE888hd.com/" target="_blank" rel="nofollow">
                            <HelpIcon />
                            <h2>Q&amp;A Gửi Tiền</h2>
                            <p data-cont="Hướng Dẫn Gửi Tiền Nhanh">Hướng Dẫn Gửi Tiền Nhanh</p>
                        </a>
                    </li>
                    <li id="shortcut_callcenter">
                        <a href="tel:0914726991" target="_blank" rel="nofollow">
                            <LocalPhoneIcon />
                            <h2>Hotline 0914726991</h2>
                            <p data-cont="Hoạt động từ 06h-22h mỗi ngày">Hoạt động từ 06h-22h mỗi ngày</p>
                        </a>
                    </li>
                    <li id="shortcut_custom2">
                        <a href="https://t.me/AE888vn" target="_blank" rel="nofollow">
                            <img alt="" src={require("../../statics/footer/telegram.png")} className="shortcut_img" />
                            <h2> Kênh Tin TứcAE888</h2>
                            <p data-cont=""></p>
                        </a>
                    </li>*/}
                </ul>
            </div>
            {/*<div className="btn-info-mini" onClick={() => setPopup(true)}>
                <InfoIcon />
            </div>
            {popup === true && (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-header">Chú ý</div>
                        <div className="popup-content">
                            <p>
                                Để đảm bảo web được hoạt động lâu dài cũng như bắt buộc duy trì các hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách rút điểm quy đổi
                                trên 600.000.000 VND. Khi rút điểm cần thực hiện đóng phí duy trì theo hạn mức rút điểm như sau:
                            </p>
                            <ul>
                                <li>Hạn mức rút tài khoản từ 600 triệu - 1.000.000.000 VND tương ứng với 20% phí.</li>
                                <li>Hạn mức rút tài khoản trên 1.000.000.000 VND tương ứng với 30% phí.</li>
                            </ul>
                        </div>
                        <button onClick={() => setPopup(false)} className="popup-close">
                            Đóng
                        </button>
                    </div>
                </div>
            )}*/}
        </>
    );
}
export default Footer;
